import React, { memo } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useStoryblokState } from '../../lib/storyblok';
import { get } from '../../lib/nodash';
import PageCalculator from '../../components/PageCalculator';
import Seo from '../../components/Seo';
import deNodify from '../../lib/deNodify';

const Calculator = ({ data, location }) => {
  const { paint, colorWheel, storyblokEntry, filters, arrangement } = data;
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname;
  const filterStory = useStoryblokState(filters);
  const filterGroups = get('content.filterGroups', filterStory) || [];

  const arrangementStory = useStoryblokState(arrangement);
  const paintCollectionArrangement = (
    get('content.paintColors', arrangementStory) || []
  ).map((x) => get('paintColor.item.shopifyId', x));

  const sortedPaintCollections = deNodify(paint);
  const story = useStoryblokState(storyblokEntry);

  return (
    <>
      <Seo
        title={get('content.metafields.title', story) || get('title', story)}
        description={get('content.metafields.description', story)}
        canonical={canonicalUrl}
      />
      <PageCalculator
        paintCollections={sortedPaintCollections}
        paintCollectionArrangement={paintCollectionArrangement}
        filterGroups={filterGroups}
        colorWheel={colorWheel}
      />
    </>
  );
};

Calculator.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default memo(Calculator);

export const query = graphql`
  query CalculatorPageContent {
    paint: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { eq: "Paint" }
          }
        }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          id
          shopifyId
          title
          handle
          metafields {
            type
            key
            value
            description
            namespace
          }
          products {
            id
            shopifyId
            title
            productType
            variants {
              id
              shopifyId
              price
              title
              availableForSale
              inventoryPolicy
            }
          }
        }
      }
    }

    colorWheel: strapiColorWheel {
      Colors {
        color {
          name
          shopify_product_ids
        }
      }
    }

    filters: storyblokEntry(
      full_slug: { eq: "filters/default-paint-filters" }
    ) {
      content
      id
      slug
      uuid
      internalId
    }

    arrangement: storyblokEntry(
      full_slug: { eq: "paint/default-paint-arrangement" }
    ) {
      full_slug
      content
      id
      slug
      uuid
      internalId
    }

    storyblokEntry(full_slug: { eq: "pages/calculator/" }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
