import React from 'react';
import PropTypes from 'prop-types';
import { dropRight, find } from '../../lib/nodash';
import { Box, Text } from 'grommet';

const CalculatorBreadcrumbMenu = ({ steps = [], current, onNavigate }) => {
  const visible = dropRight(
    steps.length - 1 - steps.indexOf(find({ id: current }, steps)),
    steps
  );

  return (
    <Box direction="row" wrap={true} gap="xsmall">
      {visible.map((x, i) => (
        <Box
          key={x.id}
          gap="xsmall"
          direction="row"
          focusIndicator={false}
          onClick={
            i < visible.length - 1 && onNavigate ? () => onNavigate(x.id) : null
          }
        >
          <Text size="12px" key={x.id}>
            {x.label}
          </Text>
          {i < visible.length - 1 && <Text size="12px">/</Text>}
        </Box>
      ))}
    </Box>
  );
};

CalculatorBreadcrumbMenu.propTypes = {
  steps: PropTypes.array.isRequired,
  current: PropTypes.string,
  onNavigate: PropTypes.func,
};

export default CalculatorBreadcrumbMenu;
