import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get, head } from '../lib/nodash';
import { Box } from 'grommet';

import {
  navigateToStep,
  setCustomerName,
  setProductId,
} from '../state/calculator/calculatorSlice';
import Calculator from './Calculator/Calculator';

const PageCalculator = ({
  paintCollections,
  paintCollectionArrangement,
  filterGroups,
  hasSamples = false,
  sampleOrders = [],
  colorWheel,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasSamples || sampleOrders.length > 0) {
      dispatch(navigateToStep('choose'));
      if (sampleOrders.length > 0) {
        dispatch(setCustomerName(get('customerName', head(sampleOrders))));
      }
    }
  }, [hasSamples, sampleOrders.length]);

  useEffect(() => {
    dispatch(setProductId(null));
    return () => {
      dispatch(setProductId(null));
    };
  }, []);

  return (
    <Box margin={{ top: 'medium' }}>
      <Calculator
        paintCollections={paintCollections}
        paintCollectionArrangement={paintCollectionArrangement}
        filterGroups={filterGroups}
        sampleOrders={sampleOrders}
        hasSamples={hasSamples}
        colorWheel={colorWheel}
      />
    </Box>
  );
};

PageCalculator.propTypes = {
  paintCollections: PropTypes.array.isRequired,
  paintCollectionArrangement: PropTypes.array,
  filterGroups: PropTypes.array.isRequired,
  hasSamples: PropTypes.bool,
  sampleOrders: PropTypes.array,
  page: PropTypes.object,
  colorWheel: PropTypes.object,
};

export default memo(PageCalculator);
