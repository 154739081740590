'use strict';

import React, { useEffect, useCallback, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import loadable from '@loadable/component';

import CalculatorBreadcrumbMenu from './CalculatorBreadcrumbMenu';
import extractGid from '../../lib/extractGid';
import { reject, isEqual, drop, map, head } from '../../lib/nodash';

import cookieStore from '../../lib/cookieStore';
import defaultPaintProduct from '../../lib/defaultPaintProduct';
import { COOKIE_GENERIC_PRODUCT_QTY } from '../../lib/constants';
import {
  submitCalculationValues,
  returnToForm,
  browseProducts,
  closeBrowseProducts,
  confirmSelectedProducts,
  checkoutSelectedProducts,
  navigateToStep,
  setProductId,
} from '../../state/calculator/calculatorSlice';

const CalcChoose = loadable(() => import('./CalcChoose'));
const CalcForm = loadable(() => import('./CalcForm'));
const CalcResults = loadable(() => import('./CalcResults'));

const Calculator = ({
  searchOptions,
  filterGroups,
  sampleOrders = [],
  paintCollections,
  paintCollectionArrangement,
  hasSamples,
  disableChoose,
  colorWheel,
}) => {
  const containerRef = useRef(null);
  const steps = disableChoose
    ? [
        { id: 'form', label: 'Calculator' },
        { id: 'results', label: 'Checkout' },
      ]
    : [
        { id: 'choose', label: "Let's Get Started" },
        { id: 'form', label: 'Calculator' },
        { id: 'results', label: 'Checkout' },
      ];
  const dispatch = useDispatch();
  const activeProductId = useSelector(
    (state) => state.calculator.activeProductId
  );
  const step = useSelector((state) => state.calculator.step);

  const calculatedProducts = useSelector(
    (state) => state.calculator.calculatedProducts
  );
  const browsePaint = useSelector((state) => state.calculator.browsePaint);
  const selectedProductIds = useSelector(
    (state) => state.calculator.selectedProductIds
  );
  const ordersToken = useSelector((state) => state.calculator.ordersToken);
  const skippedCalc = useSelector((state) => state.calculator.skippedCalc);
  const customerName = useSelector((state) => state.calculator.customerName);
  const continueTo = useSelector((state) => {
    return state.calculator.continueTo;
  });

  const handleBrowse = (options) => {
    dispatch(browseProducts(options));
  };

  const handleBrowseClose = () => {
    dispatch(closeBrowseProducts());
  };

  const handleContinue = (selectedProductIds, skipCalc) => {
    dispatch(setProductId(head(selectedProductIds)));
    if (skipCalc || (continueTo && continueTo === 'results')) {
      dispatch(
        checkoutSelectedProducts(map((x) => extractGid(x), selectedProductIds))
      );
    } else {
      dispatch(
        confirmSelectedProducts(map((x) => extractGid(x), selectedProductIds))
      );
    }
    containerRef.current.scrollIntoView();
  };

  useEffect(() => {
    const header = document.querySelector('.cpage__hero');
    if (header) {
      if (step !== 'choose') {
        header.classList.add('visually-hidden');
      } else {
        header.classList.remove('visually-hidden');
      }
    }
  }, [step]);

  const handleBackToForm = useCallback(() => {
    dispatch(returnToForm());
  }, [dispatch, returnToForm]);

  const handleCalculate = useCallback(
    (calculations, calcVariables, productId) => {
      const { gallons } = calculations;

      dispatch(
        submitCalculationValues({
          productId,
          calculations,
          calcVariables,
        })
      );

      if (isEqual(defaultPaintProduct.id, productId)) {
        cookieStore.set(COOKIE_GENERIC_PRODUCT_QTY, gallons, 1);
      }
    },
    [dispatch, cookieStore, submitCalculationValues]
  );

  const menuSteps =
    !ordersToken && hasSamples
      ? drop(1, steps)
      : (calculatedProducts || []).length > 0
      ? skippedCalc
        ? reject({ id: 'form' }, steps)
        : steps
      : steps;

  return (
    <Box justify="center" ref={containerRef}>
      <Box
        margin={{
          bottom: 'small',
          horizontal: 'large',
          top: '20px',
        }}
      >
        <CalculatorBreadcrumbMenu
          steps={menuSteps}
          current={step}
          onNavigate={(step) => dispatch(navigateToStep(step))}
        />
      </Box>
      {step === 'choose' && (
        <>
          <Box className="choose-container">
            <CalcChoose
              selectedProductIds={selectedProductIds}
              sampleOrders={sampleOrders}
              ordersToken={ordersToken}
              onContinue={handleContinue}
              customerName={customerName}
              browseAllPaint={browsePaint}
              handleClose={handleBrowseClose}
              searchOptions={searchOptions}
              filterGroups={filterGroups}
              paintCollections={paintCollections}
              paintCollectionArrangement={paintCollectionArrangement}
              colorWheel={colorWheel}
            />
          </Box>
        </>
      )}

      {(step === 'form' || !step) && (
        <Box
          pad={{ horizontal: 'large', bottom: 'large' }}
          className="cpage__block cpage__block--calculate-standalone"
          id="cpage__calculator_standalone"
        >
          <CalcForm
            productId={activeProductId}
            paintCollections={paintCollections}
            onCalculate={handleCalculate}
            onBrowse={handleBrowse}
            subtitle={
              (selectedProductIds || []).length > 1
                ? `Paint ${
                    (selectedProductIds || []).indexOf(activeProductId) + 1
                  } of ${(selectedProductIds || []).length}`
                : undefined
            }
          />
        </Box>
      )}

      {/* calculation results */}
      {step === 'results' && (
        <Box
          className="cpage__block cpage__block--calculate-standalone"
          id="cpage__calculator_standalone"
        >
          <CalcResults
            onBackToForm={handleBackToForm}
            onBrowse={handleBrowse}
            paintCollections={paintCollections}
          />
        </Box>
      )}
    </Box>
  );
};

Calculator.propTypes = {
  productId: PropTypes.string,
  featuredFilters: PropTypes.array,
  colorFilters: PropTypes.array,
  hasSamples: PropTypes.bool,
  sampleOrders: PropTypes.array,
  filterCollections: PropTypes.array,
  preventAddToCartMessage: PropTypes.string,
  continueTo: PropTypes.string,
  searchOptions: PropTypes.array,
  filterGroups: PropTypes.array,
  content: PropTypes.array,
  paintCollections: PropTypes.array,
  paintCollectionArrangement: PropTypes.array,
  disableChoose: PropTypes.bool,
  colorWheel: PropTypes.object,
};

export default memo(Calculator);
